import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    name: "sign-in2",
    component: () => import("../views/LoginView.vue"),
  },

  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("../views/LoginView.vue"),
  },

  {
    path: "/user-list",
    name: "user-list",
    component: () => import("../views/UserListView.vue"),
  },

  {
    path: "/worker-list",
    name: "worker-list",
    component: () => import("../views/WorkerListView.vue"),
  },

  {
    path: "/user-deposits",
    name: "user-deposits",
    component: () => import("../views/UserDepositsView.vue"),
  },

  {
    path: "/worker-payments",
    name: "worker-payments",
    component: () => import("../views/WorkerPaymentsView.vue"),
  },

  {
    path: "/order-reports",
    name: "order-reports",
    component: () => import("../views/OrderReportView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
